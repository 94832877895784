import React from "react"
import styled from "@emotion/styled"

const HeaderElement = styled.div`
    padding: 32px 32px;

    color: rgb(220, 220, 220);
`

const Title = styled.div`
    margin-bottom: 22px;

    font-size: 18px;
    font-weight: 700;
`

const Description = styled.div`
    display: block;

    width: 325px;

    font-size: 14px;
    line-height: 1.5em;

    @media (max-width: 450px) {
        width: 100%;
    }
`

const Link = styled.a`
    margin-top: 8px;

    color: rgb(150, 150, 150);
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`

export default () => {
    return (
        <HeaderElement>
            <Title>sslsv-demo</Title>
                
            <Description>
                Speaker verification demo based on a model trained in a self-supervised way. 
                {' '}
                <Link target="_blank" rel="noopener noreferrer" href="https://github.com/theolepage/sslsv">See project web page</Link>
            </Description>
        </HeaderElement>
    )
}