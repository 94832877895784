import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import RegisterForm from "./RegisterForm"

const RegisterContainer = styled.div`
    margin-bottom: 75px;
`

export default ({ socket, addSpeaker }) => {
    const [forms, setForms] = useState([])

    useEffect(() => {
        const newElement = <RegisterForm socket={socket} addNewForm={addNewForm} />
        setForms(oldArray => [...oldArray, newElement])
    }, [])

    const addNewForm = () => {
        const newElement = <RegisterForm socket={socket} addNewForm={addNewForm} />
        setForms(oldArray => [...oldArray, newElement])
        addSpeaker()
    }

    return (
        <RegisterContainer>
            { forms.map((element, index) => <div key={index}>{element}</div> )}
        </RegisterContainer>
    )
}