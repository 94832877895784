import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

const DELAY = 2000
const COLORS = [
    "#f94144",
    "#f3722c",
    "#f8961e",
    "#f9844a",
    "#f9c74f",
    "#90be6d",
    "#43aa8b",
    "#4d908e",
    "#577590",
    "#277da1"
]

async function requestRecorder()
{
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    return new MediaRecorder(stream)
}

const ListeningContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 0;

    color: rgb(220, 220, 220);
    text-align: center;
    font-size: 28px;
`

const ListeningSubText = styled.div`
    margin-top: 12px;

    font-size: 14px;
`

const stringToColor = text => {
    let hash = 0
    for (var i = 0; i < text.length; i++)
    {
        hash = text.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
    }

    return COLORS[hash % (COLORS.length - 1)]
}

const Recorder = ({ sendData }) => {
    const [recorder, setRecorder] = useState(null)

    useEffect(() => {
        if (recorder === null)
        {
            requestRecorder().then(setRecorder, console.error)
            return
        }

        // Bind event listener
        const handleData = e => {
            const audioBlob = new Blob([e.data], {type: recorder.mimeType})
            console.log('retrieved data => send blob')
            sendData(audioBlob)
        }
        recorder.addEventListener("dataavailable", handleData)

        // Main loop
        recorder.start()
        const progressInterval = setInterval(() => {
            recorder.stop()
            console.log('stopping => starting')
            recorder.start()
        }, DELAY)
        
        // Destructor: stop timer and unbind event
        return () => {
            recorder.removeEventListener("dataavailable", handleData)
            clearInterval(progressInterval)
        }
    }, [recorder])
}

export default ({ socket }) => {
    const [currentSpeakerName, setCurrentSpeakerName] = useState("Unknown")

    Recorder({ sendData: (data) => socket.emit('predict_speaker', data) })

    useEffect(() => {
        socket.on('predict_speaker_result', name => {
            console.log('received spk name')
            setCurrentSpeakerName(name)
        })
    }, [socket])

    const currentColor = { 'color': stringToColor(currentSpeakerName) }

    return (
        <ListeningContainer>
            <div style={currentColor}>{ currentSpeakerName }</div>
            <ListeningSubText>might be speaking right now...</ListeningSubText>
        </ListeningContainer>
    )
}