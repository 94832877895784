import React from "react"
import styled from "@emotion/styled"

const TextInputElement = styled.input`
    display: block;

    width: 100%;

    padding: 18px 14px;

    color: rgb(200, 200, 200);
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    
    background: #0f0f0f;
    border-radius: 4px;
    border: 1px solid rgb(30, 30, 30);
`

export default (props) => {
    return (
        <TextInputElement {...props} type="text" />
    )
}