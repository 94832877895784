import React from "react"
import styled from "@emotion/styled"

const ButtonElement = styled.button`
    display: block;

    width: 100%;

    margin: 20px 0;
    padding: 18px 10px;
    
    color: rgb(250, 250, 250);
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    
    box-shadow: 0 0 6px -8px rgba(100, 100, 100, 1);
    background-color: var(--color, #1462ff);
    border-radius: 6px;
    border: 0;
    cursor: pointer;

    transition: background 0.2s;

    &:hover {
        background: var(--color-dark, #1457de);
    }

    &:disabled {
        background: #0f0f0f;
        border: 1px solid rgb(30, 30, 30);
        cursor: not-allowed;
    }
`

export default (props) => {
    let color = null
    let color_dark = null
    if (props.color == 'green')
    {
        color = '#38c784'
        color_dark = '#2eab70'
    }
    else if (props.color == 'red')
    {
        color = '#de4b4b'
        color_dark = '#c44141'
    }

    return (
        <ButtonElement {...props} style={{
            '--color': color,
            '--color-dark': color_dark
        }} />
    )
}