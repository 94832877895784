import React, { useState } from "react"
import styled from "@emotion/styled"

import TextInput from "./components/TextInput"
import AudioRecorder from "./components/AudioRecorder"

const RegisterFormElement = styled.div`
    margin: 25px 0;
`

export default ({ socket, addNewForm }) => {
    const [name, setName] = useState('')
    const [finished, setFinished] = useState(false)

    const onFinished = (blob) => {
        socket.emit('register_speaker', name, blob)
        setFinished(true)
        addNewForm()
    }

    const handleNameChange = (e) => setName(e.target.value)

    return (
        <RegisterFormElement>
            <TextInput
                placeholder={"What's your name?"}
                disabled={finished} 
                value={name}
                onChange={handleNameChange} />

            { !finished && 
                <AudioRecorder
                    disabled={name === '' || finished}
                    socket={socket}
                    onFinished={onFinished} />
            }
        </RegisterFormElement>
    )
}