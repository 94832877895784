import { useEffect, useState } from "react"

import Button from "./Button"

const RECORDING_TIME = 10

async function requestRecorder()
{
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    return new MediaRecorder(stream)
}

const Recorder = () => {
    const [blob, setBlob] = useState(null)
    const [recorder, setRecorder] = useState(null)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (recorder === null)
        {
            requestRecorder().then(setRecorder, console.error)
            return
        }

        const handleData = e => {
            const audioBlob = new Blob([e.data], {type: recorder.mimeType})
            setBlob(audioBlob)
        }

        recorder.addEventListener("dataavailable", handleData)

        return () => recorder.removeEventListener("dataavailable", handleData)
    }, [recorder])

    const startRecording = () => {
        if (recorder === null)
            return

        recorder.start()

        let localProgress = 0
        setProgress(++localProgress)
        const progressInterval = setInterval(() => {
            setProgress(++localProgress)
        }, 1000)
        
        // Wait 3 seconds and stop recording
        setTimeout(() => {
            clearInterval(progressInterval)
            recorder.stop()
        }, RECORDING_TIME * 1000)
    }

    return [blob, startRecording, progress]
}

const AudioRecorder = ({ onFinished, disabled }) => {
    const [hasFinishedRecording, setHasFinishedRecording] = useState(false)
    
    let [blob, startRecording, progress] = Recorder()
    
    useEffect(() => {
        if (blob != null && !hasFinishedRecording)
        {
            setHasFinishedRecording(true)
            onFinished(blob)
        }
    }, [hasFinishedRecording, blob])

    const isRecording = (progress != 0)
    const text = isRecording
        ? `Recording... (${RECORDING_TIME + 1 - progress}s remaining)`
        : 'Record yourself!'

    return (
        <div>
            <Button
                color={isRecording ? 'red' : 'blue'}
                disabled={disabled}
                onClick={startRecording}>
                    { text }
            </Button>
        </div>
    )
}

export default AudioRecorder