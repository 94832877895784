import React, { useState, useEffect } from "react"
import socketIOClient from "socket.io-client"
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"

import Button from "./components/Button"
import Header from "./Header"
import Listening from "./Listening"
import Register from "./Register"

const SOCKET_IO_ENDPOINT = "https://api.sslsv-demo.theolepage.com"
const socket = socketIOClient(SOCKET_IO_ENDPOINT)

const GLOBAL_STYLE = css`
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;

        font-family: 'Lato', sans-serif;

        background: #0f0f0f;
    }
`

const Container = styled.div`
    max-width: 400px;

    margin: 0 auto;

    @media (max-width: 450px) {
        max-width: 80%;
    }
`

export default () => {
    useEffect(() => {
        // Disconnect socket connection
        return () => socket.disconnect()
    }, [])

    const [startListening, setStartListening] = useState(false)
    const [countSpeakers, setCountSpeakers] = useState(0)

    const clickStartListening = () => setStartListening(true)
    const addSpeaker = () => setCountSpeakers(countSpeakers + 1)

    return (
        <div>
            <Global styles={GLOBAL_STYLE} />

            <Header />

            { !startListening &&
                <Container>
                    <Register socket={socket} addSpeaker={addSpeaker} />
                    <Button 
                        disabled={countSpeakers == 0}
                        color="green"
                        onClick={clickStartListening}>
                        Start listening
                    </Button>
                </Container>
            }
            
            { startListening && <Listening socket={socket} /> }
        </div>
    )
}